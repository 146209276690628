import { useQuery } from '@tanstack/react-query';
import { FormattedLocationResponse, getPlaceFromLocationSlug } from '../../api';
import { useLocationContext } from '../../context';

export function useLocationByLocationSlug(
    locationSlug: string,
    initialLocation?: FormattedLocationResponse,
) {
    const response = useQuery(
        ['category-browse', locationSlug],
        async () => await getPlaceFromLocationSlug(locationSlug),
        {
            enabled: !!locationSlug,
            initialData:
                locationSlug === initialLocation?.slug
                    ? initialLocation
                    : undefined,
        },
    );

    return response;
}

export function useLocation() {
    const { locationSlug } = useLocationContext();

    return useLocationByLocationSlug(locationSlug);
}
